/*-
 * ╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲
 * Sentry Maven Skin
 * ჻჻჻჻჻჻
 * Copyright (C) 2017 - 2024 Sentry Software
 * ჻჻჻჻჻჻
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱
 */
/* style sheet for "A4" printing */
@media print and (width: 21cm) and (height: 29.7cm) {
	@page {
		margin: 0.98cm;
	}
}

/* style sheet for "letter" printing */
@media print and (width: 8.5in) and (height: 11in) {
	@page {
		margin: 0.4in;
	}
}

@media print {

	.site-logo-xs,
	.parents-xs,
	.home-xs,
	.subtopics-xs,
	.breadcrumb,
	nav[role="navigation"],
	footer {
		display: none !important;
	}

	.main-content {
		width: 100% !important;
	}

	body {
		padding-top: 0px !important;
	}

	/* CSS below taken from HTML5 Boilerplate */
	* {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: h5bp.com/s */
		box-shadow:none !important;
		text-shadow: none !important;
	}

	a,
	a:visited {
		text-decoration: underline;
	}

	a[href]:after {
		content: none !important;
		padding-left: 0;
		padding-right: 0;
	}

	abbr[title]:after {
		content: " (" attr(title) ")";
	}

	/* Show zoomable images */
	zoomable {
		width: 100%;
	}
	zoomable img {
		opacity: 1;
	}
	.zoomable .zoomable-title {
		opacity: 1;
		font-size: 100%;
	}

	pre,
	blockquote {
		border-left: 5px solid #999 !important;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

}
