/*-
 * ╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲
 * Sentry Maven Skin
 * ჻჻჻჻჻჻
 * Copyright (C) 2017 - 2024 Sentry Software
 * ჻჻჻჻჻჻
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 * ╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱╲╱
 */
/**
 * Fonts
 **/

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Bold.woff2') format('woff2'),
			 url('../fonts/Raleway-Bold.woff') format('woff'),
			 url('../fonts/Raleway-Bold.ttf') format('truetype'),
			 local('Helvetica');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Regular.woff2') format('woff2'),
			 url('../fonts/Raleway-Regular.woff') format('woff'),
			 url('../fonts/Raleway-Regular.ttf') format('truetype'),
			 local('Helvetica');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Lato';
	src: url('../fonts/Lato-Regular.woff2') format('woff2'),
			 url('../fonts/Lato-Regular.woff') format('woff'),
			 url('../fonts/Lato-Regular.ttf') format('truetype'),
			 local('Arial');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/**
 * Variables
 **/

/* Fonts */
/* START SNIPPET: fonts */
:root {
	--content-font: 'Lato', sans-serif;
	--content-font-size: 15px;

	--title-font: 'Raleway', sans-serif;
	--heading-font: 'Raleway', sans-serif;

	--top-font: var(--heading-font);
	--top-font-size: small;
	--top-font-weight: normal;
	--top-font-style: normal;

	--left-title-font: var(--title-font);
	--left-title-font-size: var(--content-font-size);
	--left-title-font-weight: var(--main-title-font-weight);
	--left-title-font-style: var(--main-title-font-style);

	--left-font: var(--content-font);
	--left-font-size: var(--content-font-size);
	--left-font-weight: normal;
	--left-font-style: normal;

	--banner-font: var(--title-font);
	--banner-font-size: xx-large;
	--banner-font-weight: bold;
	--banner-font-style: normal;

	--main-title-font: var(--title-font);
	--main-title-font-size: 40px;
	--main-title-font-weight: bold;
	--main-title-font-style: normal;

	--h2-font-size: xx-large;
	--h2-font-weight: bold;
	--h2-font-style: normal;

	--h3-font-size: x-large;
	--h3-font-weight: normal;
	--h3-font-style: normal;

	--h4-font-size: large;
	--h4-font-weight: normal;
	--h4-font-style: italic;

	/* Bottom same as top */
	--bottom-font: var(--top-font);
	--bottom-font-size: var(--top-font-size);
	--bottom-font-weight: var(--top-font-weight);
	--bottom-font-style: var(--top-font-style);
}
/* END SNIPPET: fonts */

/* START SNIPPET: colors */
body {
	--banner-bgcolor: #3399ff;
	--banner-fgcolor: #fff;
	--main-bgcolor: #fff;
	--main-fgcolor: #000;
	--alternate-bgcolor: #c0defd;
	--alternate-fgcolor: #013e7a;
	--link-color: #266eb7;;

	--success-bg: #d6e9c6;
	--success-fg: #32661c;
	--info-bg: #bce8f1;
	--info-fg: #0e5172;
	--warning-bg: #faebcc;
	--warning-fg: #7f6c08;
	--danger-bg: #ebccd1;
	--danger-fg: #680c0c;
}
/* END SNIPPET: colors */

/* Dark colors */
/* START SNIPPET: dark-colors */
body.dark {
	--main-bgcolor: #343434;
	--main-fgcolor: #fff;
	--link-color: #81cbf7;
	--success-bg: #32661c;
	--success-fg: #d6e9c6;
	--info-bg: #0e5172;
	--info-fg: #bce8f1;
	--warning-bg: #7f6c08;
	--warning-fg: #faebcc;
	--danger-bg: #680c0c;
	--danger-fg: #ebccd1;
}
/* END SNIPPET: dark-colors */

/* Alternate colors of the banner */
body.sentry-green {
	--banner-bgcolor: #76A600;
	--alternate-bgcolor: #4a6800;
	--alternate-fgcolor: #fff;
}
body.sentry-orange {
	--banner-bgcolor: #db5d18;
	--alternate-bgcolor: #ffb992;
	--alternate-fgcolor: #762a00;
}
body.sentry-purple {
	--banner-bgcolor: #b071dc;
	--alternate-bgcolor: #766185;
	--alternate-fgcolor: #fff;
}

/* Fix the pointer issue between Bootstrap and Angular */
.nav, .pagination, .carousel, .panel-title a, a, th { cursor: pointer; }

/**
 * General settings
 */
html,body {
	font-family: var(--content-font);
	font-size: var(--content-font-size);
}

/**
 * Logo Banner
 **/

.site-logo {
	background-color: #343434;
	border-radius: 0;
	border-width: 0;
	margin-bottom: 0;
	padding-left: 10px;
	padding-right: 10px;
}

.site-logo.site-logo-xs {
	min-height: 0;
}

.site-logo.additional-links {
	padding-left: 0;
	padding-right: 0;
	margin-left: -10px;
}

/* Breadcrumbs in mobile mode */
.site-logo.site-logo-xs.breadcrumb-xs {
	background-color: #555;
}

.site-logo.site-logo-xs ul.nav.navbar-nav {
	margin-top: 5px;
	margin-bottom: 5px;
}

.site-logo ul.nav.navbar-nav > li > a,
.site-logo .navbar-header > a {
	font-family: var(--top-font);
	font-weight: var(--top-font-weight);
	font-style: var(--top-font-style);
	font-size: var(--top-font-size);
	letter-spacing: 1px;
	font-weight: 400;
	text-transform: uppercase;
	color: #fff;
	opacity: .6;
	filter: alpha(opacity=50);
	transition: .2s ease-in-out;
	height: 50px;
	padding-left: 10px;
	padding-right: 10px;
}

.site-logo.site-logo-xs ul.nav.navbar-nav > li > a {
	text-align: center;
	padding-top: 0;
	padding-bottom: 0;
	height: auto;
}

.site-logo ul.nav.navbar-nav > li > a:hover,
.site-logo .navbar-header > a.navbar-brand:hover {
	opacity: 1;
	filter: alpha(opacity=100);
}

.site-logo .navbar-header > a.navbar-brand,
.site-logo ul.nav.navbar-nav.navbar-right > li > a {
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	line-height: 40px;
	font-size: small;
}

.site-logo ul.nav.navbar-nav.navbar-right > li > a {
	margin-right: 15px;
}

.site-logo .navbar-header > a.navbar-brand > img,
.site-logo ul.nav.navbar-nav.navbar-right > li > a > img {
	max-height: 40px;
	display: inline;
	vertical-align: top;
}

.site-logo ul.nav.navbar-nav.navbar-right {
	margin-right: -10px;
}

/**
 * Site Banner
 **/

body.sentry-site .site-banner {
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 0;
	border-width: 0;
	font-family: var(--banner-font);
	font-size: var(--banner-font-size);
	font-weight: var(--banner-font-weight);
	font-style: var(--banner-font-style);
	color: var(--banner-fgcolor);
	margin-bottom: 0;
}

/* Color of the solution */
body.sentry-site .site-banner { background-color: var(--banner-bgcolor) }

.site-banner .navbar-header {
	text-transform: uppercase;
	position: relative;
}

body.sentry-site .site-banner .navbar-brand {
	padding-top: 10px;
	padding-bottom: 10px;
	/* Leaves space for the search loop, which is 2em (xx-large) + 20px padding + 15px margin */
	max-width: calc(100vw - 2em - 35px);
	height: unset;
	position: relative;
	font-size: inherit;
}

body.sentry-site .site-banner .navbar-toggle {
	border-style: none;
}

body.sentry-site .site-banner .navbar-header .header-title {
	line-height: 1em;
	margin-top: 0;
}

body.sentry-site .site-banner .navbar-header .header-subtitle {
	font-family: var(--heading-font);
	font-size: var(--content-font-size);
	font-weight: normal;
	font-style: normal;
	line-height: 1em;
	letter-spacing: 1px;
}

body.sentry-site .site-banner .navbar-header .header-subtitle .version-prefix {
	opacity: 0.8;
}

body.sentry-site .site-banner .navbar-header .header-subtitle .version {
	font-weight: bold;
}

/* Breadcrumbs above the main banner */
body.sentry-site .site-banner ol.breadcrumb {
	margin-bottom: -3px;
	padding: 0;
	background-color: inherit;
	border: none;
	font-family: var(--content-font);
	font-size: var(--content-font-size);
	font-weight: normal;
	font-style: normal;
	text-transform: none;
}

body.sentry-site .site-banner ol.breadcrumb > li:not(:has(a)),
body.sentry-site .site-banner ol.breadcrumb > li > a:not([href]),
body.sentry-site .site-banner ol.breadcrumb > li > a:hover:not([href]) {
	cursor: default;
	opacity: 0.8;
}

body.sentry-site .site-banner ol.breadcrumb > li+li:before {
	opacity: 0.8;
}

body.sentry-site .site-banner ol.breadcrumb > li > a {
	color: var(--banner-fgcolor);
	text-decoration: none;
	opacity: 0.8;
	transition: .2s ease-in-out;
}

body.sentry-site .site-banner ol.breadcrumb > li > a:hover {
	text-decoration: none;
	opacity: 1;
}

/* Corrected margin for the search box to the right */
body.sentry-site .site-banner .navbar-right {
	margin-right: -10px;
}

/* The reduced title for xs displays when showing search results */
body.sentry-site .site-banner .navbar-header .header-xxs {
	position: absolute;
}

body.sentry-site .site-banner .navbar-header .header-xxs .header-title {
	font-size: medium;
	line-height: 1em;
	margin-top: -1em;
}

body.sentry-site .site-banner .navbar-header .header-xxs .header-subtitle {
	font-size: x-small;
	line-height: 1em;
}

/* Show the "normal" banner when no .show-search-xs */
body.sentry-site .site-banner .navbar-header .navbar-brand {
	opacity: 1;
	transition: opacity 0.1s, transform 0.1s;
	transform-origin: top left;
}

/* Hide the "normal" banner when .show-search-xs is set */
body.sentry-site .site-banner .navbar-header.show-search-xs .navbar-brand:not(.header-xxs) {
	opacity: 0;
	transform: scale(0.2);
}

/* Hide the minimized banner when no .show-search-xs */
body.sentry-site .site-banner .navbar-header .navbar-brand.header-xxs {
	opacity: 0;
}

/* Show the minimized banner when .show-search-xs is set */
body.sentry-site .site-banner .navbar-header.show-search-xs .navbar-brand.header-xxs {
	opacity: 1;
}

/* Light/dark toggle */
li.dark-toggle {
	margin-right: 15px;
}
li.dark-toggle.dark-toggle-xs {
	margin-right: 0px;
}

.toggle.dark-toggle,
.toggle-on.dark-toggle,
.toggle-off.dark-toggle {
	border-radius: 11px;
}

.toggle.dark-toggle.dark-toggle-xs,
.toggle-on.dark-toggle.dark-toggle-xs,
.toggle-off.dark-toggle.dark-toggle-xs {
	border-radius: 11px;
}

.dark-toggle .toggle-off.btn.day.active, .dark-toggle .toggle-off.btn.day {
	color: yellow;
	background-color: #357bd5;
	transition: background-color 0.2s ease-in-out;
}

.dark-toggle .toggle-off.btn.day.active:hover, .dark-toggle .toggle-off.btn.day:hover {
	background-color: #57a0ff;
}

.dark-toggle .toggle-on.btn.night.active, .dark-toggle .toggle-on.btn.night {
	color: rgb(247, 247, 105);
	background-color: #1f4570;
	transition: background-color 0.2s ease-in-out;
}

.dark-toggle .toggle-on.btn.night.active:hover, .dark-toggle .toggle-on.btn.night:hover {
	background-color: #315f95;
}

.toggle.dark-toggle .toggle-handle {
	border-radius: 20px;
	background-color: #AEAEAE;
	border-style: none;
}

.dark-toggle i.sun-icon {
	width: 17px;
	height: 17px;
	display: inline-block;
	color: yellow;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url("data:image/svg+xml,%3Csvg width='17px' height='17px' viewBox='0 0 24 24' fill='%23FFFF00' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='5'/%3E%3Cline x1='12' y1='1' x2='12' y2='4' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='12' y1='20' x2='12' y2='23' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='1' y1='12' x2='4' y2='12' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='20' y1='12' x2='23' y2='12' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='4.3' y1='4.3' x2='6.5' y2='6.5' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='17.5' y1='17.5' x2='19.7' y2='19.7' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='4.3' y1='19.7' x2='6.5' y2='17.5' stroke='%23FFFF00' stroke-width='2'/%3E%3Cline x1='17.5' y1='6.5' x2='19.7' y2='4.3' stroke='%23FFFF00' stroke-width='2'/%3E%3C/svg%3E%0A");
}
.dark-toggle.dark-toggle-xs .toggle label.toggle.night {
	font-size: 19px;
}
.dark-toggle.dark-toggle-xs i.sun-icon {
	width: 19px;
	height: 19px;
}

/**
 * Search input
 **/

.site-search .form-group {
	font-family: var(--content-font);
	font-size: var(--content-font-size);
}
.site-search input.form-control {
	font-family: var(--content-font);
	font-size: var(--content-font-size);
	font-weight: normal;
	font-style: normal;
	padding: 5px 25px 5px 5px;
	border: none;
	border-bottom: 1px solid var(--banner-fgcolor);
	background: transparent;
	color: var(--banner-fgcolor) !important;
	border-radius: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	width: 300px !important;
}

/* Workaround a CSS bug? We need to repeat this rule for each */
/* browser variant, otherwise it's not taken into account if we */
/* group them in a multiple selector separated with commas 🤷‍♂️ */
.site-search input.form-control::placeholder {
 	color: var(--banner-fgcolor);
 }

 .site-search input.form-control::-webkit-input-placeholder {
 	color: var(--banner-fgcolor);
 }

.site-search input.form-control:-moz-placeholder
 {
 	color: var(--banner-fgcolor);
 }

.site-search input.form-control::-moz-placeholder {
 	color: var(--banner-fgcolor);
 }

.site-search input.form-control:-ms-input-placeholder {
	color: var(--banner-fgcolor);
}

.site-search input.form-control:focus {
	border-bottom: 1px solid var(--banner-fgcolor);
	-webkit-box-shadow: none;
	box-shadow: none;
}

.site-search.navbar-form > .form-group.has-feedback > i.form-control-feedback {
	top: calc(28px - 1em);
	font-size: larger;
}

.site-search.site-search-xs {
	box-shadow: none;
	margin-top: 14px;
	margin-left: 0;
	margin-right: 0;
	position: absolute;
	opacity: 0;
	transition: opacity 0.1s;
}

.show-search-xs .site-search-xs {
	opacity: 1;
}

.site-search.site-search-xs input.form-control {
	font-size: x-large;
	width: calc(100vw - 35px - 2em);
	padding: 5px;
}

/**
 * Search Results
 **/

.search-results .search-results-content .search-results-entry {
	margin-top: 20px;
	margin-bottom: 5px;
}

.search-results .search-results-content .search-results-parents {
	letter-spacing: 1px;
}

.search-results .search-results-content .search-results-parents .search-results-parents-entry:not(:last-child)::after {
	content: "/";
	margin-left: 5px;
	margin-right: 5px;
}

.search-results .search-results-content .search-results-title {
	font-size: larger;
	margin-bottom: 5px;
}

.search-results .search-results-content.search-results-content-xs .search-results-title {
	font-size: var(--content-font-size);
}

.search-results .search-results-content a {
	text-decoration: none;
}

.search-results .search-results-content a:hover {
	text-decoration: underline;
}

.search-results .search-results-content.search-results-content-xs a:hover,
.search-results .search-results-content.search-results-content-xs a:active {
	text-decoration: none;
}

.search-results .search-results-content.search-results-content-xs .search-results-parents {
	color: var(--main-fgcolor);
	opacity: 0.5;
}

.search-results .search-results-content i {
	margin-right: 5px;
}

.search-results-content .progressbar {
	width: 50%;
	height: 3px;
	margin-bottom: 5px;
}

.search-results-content.search-results-content-xs .progressbar {
	width: 100%;
}

.search-results-content .progressbar .bar-fg,
.search-results-content .progressbar .bar-bg {
	position: relative;
	height: 3px;
	max-width: 100%;
}

.search-results-content .progressbar .bar-fg {
	background-color: var(--alternate-bgcolor);
	width: 0;
	transition: width 0.5s ease-in-out;
	top: -3px;
}

.search-results-content .progressbar .bar-bg {
	background-color: var(--main-fgcolor);
	width: 100%;
	opacity: 0.15;
}

.search-results-content.search-results-content-xs .extract {
	color: var(--main-fgcolor);
	opacity: 0.5;
}

.search-results-content mark {
	color: inherit;
	background-color: inherit;
	font-weight: bold;
	padding: 0;
}


/**
 * Main content
 **/

.main-content {
	min-height: 100vh;
	padding-left: 30px;
	padding-right: 30px;
	padding-top: 15px;
	padding-bottom: 15px;
	font-family: var(--content-font);
	font-size: var(--content-font-size);
	line-height: 1.5;
	background-color: var(--main-bgcolor);
	color: var(--main-fgcolor);
	transition: background-color 1s ease-in-out, color 1s ease-in-out;
}

/* For small devices, use smaller margins */
@media only screen and (max-width: 575.98px) {
	.main-content {
		padding-left: 15px;
		padding-right: 15px;
	}
}

.main-content a {
	color: var(--link-color);
	text-decoration: underline;
}

a.externalLink:after {
	content: "\f08e";
	font-family: FontAwesome;
	font-weight: normal;
	font-style: normal;
	font-size: smaller;
	display: inline-block;
	text-decoration: none;
	padding-left: 3px;
	padding-right: 2px;
}

.main-content ul ul,
.main-content ul ol,
.main-content ol ol,
.main-content ol ul {
	margin-bottom: 10px;
}

.main-content p {
	margin: 0 0 10px;
}

.main-content h1 {
	text-transform: uppercase;
	font-family: var(--main-title-font);
	font-size: var(--main-title-font-size);
	font-weight: var(--main-title-font-weight);
	font-style: var(--main-title-font-style);
	line-height: 1em;
	text-rendering: optimizelegibility;
}

.main-content h1::after {
	content: '';
	display: block;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 40px;
	border-top: 2px solid var(--main-fgcolor);
	opacity: 0.5;
}

.main-content h2,
.main-content h3,
.main-content h4,
.main-content h5,
.main-content h6,
.main-content .toc-heading {
	margin-top: 30px;
	margin-bottom: 10px;
	font-family: var(--heading-font);
	line-height: 1em;
	color: inherit;
	text-rendering: optimizelegibility;
}

.main-content h2 {
	font-size: var(--h2-font-size);
	font-weight: var(--h2-font-weight);
	font-style: var(--h2-font-style);
}

.main-content h3 {
	font-size: var(--h3-font-size);
	font-weight: var(--h3-font-weight);
	font-style: var(--h3-font-style);
}

.main-content h4 {
	font-size: var(--h4-font-size);
	font-weight: var(--h4-font-weight);
	font-style: var(--h4-font-style);
}

/* Headings are surrounded by <a href="#id"> */
/* Make sure the link is unstylized */
.main-content h2>a,
.main-content h3>a,
.main-content h4>a,
.main-content h5>a,
.main-content h6>a {
	color: unset;
	text-decoration: unset;
}

/* Add a link icon after headings that appears on mouse hover and focus */
.main-content h2>a::after,
.main-content h3>a::after,
.main-content h4>a::after,
.main-content h5>a::after,
.main-content h6>a::after {
	content: "\f0c1";
	font-family: FontAwesome;
	font-weight: normal;
	font-style: normal;
	font-size: smaller;
	display: inline-block;
	text-decoration: none;
	padding-left: 7px;
	padding-right: 2px;
	opacity: 0;
	position: absolute;
	transition: opacity 0.3s ease-in-out;
}

.main-content h2>a:hover::after,
.main-content h3>a:hover::after,
.main-content h4>a:hover::after,
.main-content h5>a:hover::after,
.main-content h6>a:hover::after,
.main-content h2>a:focus::after,
.main-content h3>a:focus::after,
.main-content h4>a:focus::after,
.main-content h5>a:focus::after,
.main-content h6>a:focus::after {
	opacity: 1;
}

.main-content .toc-heading {
	font-family: var(--heading-font);
	font-size: var(--h3-font-size);
	font-weight: var(--h3-font-weight);
	font-style: var(--h3-font-style);
}

.main-content blockquote {
	margin-top: 0;
	margin-bottom: 10px;
	font-size: inherit;
	position: relative;
	z-index: 1;
	border: none;
}

.main-content blockquote::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-left: 5px solid var(--main-fgcolor);
	opacity: 0.1;
	z-index: -1;
	pointer-events: none;
	transition: border-color 1s ease-in-out;
}

/* Document footer */

.main-content .document-footer .keywords,
.main-content .document-footer .footnotes,
.main-content .document-footer .authors,
.main-content .document-footer .date {
	margin-bottom: 15px;
}

.main-content .document-footer .footnotes,
.main-content .document-footer .authors,
.main-content .document-footer .date {
	color: var(--main-fgcolor);
	opacity: 0.6;
	transition: opacity 0.2s ease-in-out;
}

.main-content .document-footer::before {
	content: '';
	display: block;
	margin-top: 45px;
	margin-bottom: 15px;
	width: 40px;
	border-top: 1px solid var(--main-fgcolor);
	opacity: 0.6;
}

.main-content .document-footer .keywords .label {
	position: relative;
	color: var(--main-bgcolor);
	background: none;
	font-family: var(--main-title-font);
	font-weight: var(--main-title-font-weight);
	font-style: var(--main-title-font-style);
	letter-spacing: 1px;
	text-transform: lowercase;
	transition: color 0.2s ease-in-out;
	cursor: default;
	z-index: 1;
}

.main-content .document-footer .keywords .label::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-fgcolor);
	opacity: 0.6;
	z-index: -1;
	border-radius: 2px;
	pointer-events: none;
	transition: opacity 0.2s ease-in-out, background-color 0.2s ease-in-out;
}

.main-content .document-footer .keywords .label:hover,
.main-content .document-footer .keywords .label:hover::after,
.main-content .document-footer .footnotes:hover,
.main-content .document-footer .authors:hover,
.main-content .document-footer .date:hover {
	opacity: 1;
}

.main-content .document-footer .keywords .label:hover {
	color: var(--banner-fgcolor);
}
.main-content .document-footer .keywords .label:hover::after {
 background-color: var(--banner-bgcolor);
}

/**
 * Left menu
 **/

body.sentry-site nav.left-menu {
	font-family: var(--left-font);
	font-size: var(--left-font-size);
	font-weight: var(--left-font-weight);
	font-style: var(--left-font-style);
	background-color: #383838;
	border: none;
	padding: 0;
}

nav.left-menu h5 {
	font-family: var(--left-title-font);
	font-size: var(--left-title-font-size);
	font-weight: var(--left-title-font-weight);
	font-style: var(--left-title-font-style);
	letter-spacing: 1px;
	text-transform: uppercase;
	color: #fff;
	opacity: 0.9;
	margin-top: 20px;
	margin-left: 15px;
	margin-bottom: 2px;
	margin-right: 0;
	padding: 0;
}

nav.left-menu a {
	transition:.2s ease-in-out;
}

nav.left-menu > ul > li:first-child {
	border-top: 1px solid #464646;
}

nav.left-menu ul > li {
	margin-top: 0;
}

nav.left-menu ul > li > a {
	padding-top: 6px;
	padding-bottom: 6px;
	border-bottom: 1px solid #464646;
	border-radius: 0px;
	background-color: #343434;
	color: #bbbbbb;
}

nav.left-menu ul > li:not(.active) > a:hover {
	background-color: #404040;
	color: #ffffff;
	padding-left: 17px;
}

nav.left-menu ul > li.active > a,
nav.left-menu ul > li.active > a:hover {
	background-color: var(--alternate-bgcolor);
	color: var(--alternate-fgcolor);
}

nav.left-menu ul > li > a:focus {
	background-color: #bbb;
	color: #343434;
}

nav.left-menu ul > li.active > a::after {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	border-style: solid;
	border-width: 1em 1em 1em 0;
	border-color: transparent var(--main-bgcolor) transparent transparent;
	transform: translateY(-50%);
	transition: border-color 1s ease-in-out;
}

nav.left-menu ul ul > li > a {
	padding-left: 30px;
}

nav.left-menu ul ul > li:not(.active) > a:hover {
	padding-left: 32px;
}

nav.left-menu ul li a i {
	margin-right: 5px;
}

nav.left-menu ul li a .badge {
	font-size: 70%;
	background-color: var(--alternate-bgcolor);
	color: var(--alternate-fgcolor);
}

body.sentry-site nav.left-menu code {
	color: white;
}

/* Left menu collapse animation */
nav.left-menu .ng-hide-animate:not(.ng-hide) {
	transform: scaleY(1);
}

nav.left-menu .ng-hide-animate.ng-hide {
	transform: scaleY(0);
}

nav.left-menu .ng-hide-animate {
	transition: all linear 0.25s;
	transform-origin: top;
}

/**
 * Make Bootstrap 3 columns full height using flex
 **/

.row.display-flex {
	display: flex;
	flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
	display: flex;
	flex-direction: column;
}

/**
 * Breadcrumbs
 **/

.parents ol.breadcrumb > li > a {
	color: var(--link-color);
	text-decoration: none;
}

.parents ol.breadcrumb > li > a:hover {
	text-decoration: underline;
}

.parents ol.breadcrumb > li > a > i {
	margin-right: 5px;
}

.parents-xs ul > a {
	text-decoration: none;
}

.parents-xs ul > a > i,
.parents-xs ul > li > i {
	margin-right: 5px;
}

.parents-xs ul > a:hover {
	color: var(--link-color);
}

.parents-xs ul > a:nth-child(2),
.parents-xs ul > li:nth-child(2) {
	padding-left: 30px;
}

.parents-xs ul > a:nth-child(3),
.parents-xs ul > li:nth-child(3) {
	padding-left: 45px;
}

.parents-xs ul > a > .badge,
.parents-xs ul > li > .badge {
	color: var(--alternate-fgcolor);
	background-color: var(--alternate-bgcolor);
}

/**
 * Table of content, on the right side (for large screens)
 **/

.toc-side-container {
	position: sticky;
	top: 0px;
}

.toc-side-container .toc {
	position: relative;
	padding: 10px 20px;
	margin-top: 20px;
	font-size: var(--content-font-size);
	border-radius: 2px;
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);
	z-index: 1;
}

/* Add a light background to the ToC */
.toc-side-container .toc::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-fgcolor);
	opacity: 0.05;
	z-index: -1;
	border-radius: 2px;
	pointer-events: none;
}

.toc-side-container .toc:hover {
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.toc-side-container .toc > .toc-heading {
	font-family: var(--main-title-font);
	font-weight: var(--main-title-font-weight);
	font-style: var(--main-title-font-style);
	text-transform: uppercase;
	font-size: larger;
	display: block;
	opacity: 0.6;
	margin: 5px 0 5px 0;
}

.toc-side-container .toc > .toc-heading::after {
	content: '';
	display: block;
	margin: 20px 0;
	width: 30px;
	border-top: 2px solid var(--main-fgcolor);
}

.toc-side-container .toc ul {
	list-style: none;
	padding-left: 0px;
	font-weight: bold;
}

.toc-side-container .toc ul li {
	margin: 15px 0;
}

.toc-side-container .toc ul ul {
	list-style: none;
	padding-left: 20px;
	font-weight: normal;
}

.toc-side-container .toc ul ul li {
	margin: 2px 0;
}

.toc-side-container .toc ul li a {
	color: var(--link-color);
	text-decoration: none;
}

.toc-side-container .toc ul li a:hover {
	text-decoration: underline;
}

/**
 * Table of content, inline (for non-large screens)
 **/

.toc-inline-container {
	margin-bottom: 15px;
}

.toc-inline-container #toc {
	position: relative;
	padding-left: 15px;
}

/* Add a light background to the ToC */
.toc-inline-container #toc::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-left-color: var(--main-fgcolor);
	border-left-style: solid;
	border-left-width: 5px;
	opacity: 0.05;
	pointer-events: none;
}

.toc-inline-container ul {
	list-style: none;
	padding-left: 0px;
	font-weight: bold;
}

.toc-inline-container ul li {
	margin: 5px 0;
}

.toc-inline-container ul ul {
	list-style: none;
	padding-left: 20px;
	font-weight: normal;
}

.toc-inline-container ul ul li {
	margin: 2px 0;
}


/**
 * Subtopics (and home on XS displays)
 **/

.subtopics::before,
.subtopics-xs::before {
	content: '';
	display: block;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 40px;
	border-top: 2px solid var(--main-fgcolor);
	opacity: 0.75;
}

.subtopics ul {
	list-style-type: none;
	padding-inline-start: 0px;
}

.subtopics ul > li > a > i {
	margin-right: 7px;
}

.subtopics-xs ul > a,
.home-xs ul > a {
	text-decoration: none;
	padding-left: 30px;
}

.subtopics-xs ul > a:hover,
.home-xs ul > a:hover {
	color: var(--link-color);
}

.subtopics-xs ul > a > i,
.subtopics-xs ul > li > i,
.home-xs ul > a > i,
.home-xs ul > li > i {
	margin-right: 5px;
}

.subtopics-xs ul > a > i.pull-right,
.subtopics-xs ul > li > i.pull-right,
.home-xs ul > a > i.pull-right,
.home-xs ul > li  > i.pull-right {
	margin-right: 0;
	margin-top: 5px;
}

.subtopics-xs ul > a > .badge,
.subtopics-xs ul > li > .badge,
.home-xs ul > a > .badge,
.home-xs ul > li > .badge {
	color: var(--alternate-fgcolor);
	background-color: var(--alternate-bgcolor);
}

.home-xs::after {
	content: '';
	display: block;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 40px;
	border-top: 2px solid var(--main-fgcolor);
	opacity: 0.75;
}



/**
 * Footer
 **/

.footer {
	background-color: #343434;
	color: #fff;
	padding-top: 50px;
	padding-bottom: 30px;
	padding-left: 100px;
	padding-right: 30px;
	font-family: var(--bottom-font);
	font-size: var(--bottom-font-size);
	font-weight: var(--bottom-font-weight);
	font-style: var(--bottom-font-style);
	letter-spacing: 1px;
	text-transform: uppercase;
}

/* On mobile, don't add that extra space */
@media only screen and (max-width: 575.98px) {
	.footer {
		padding-top: 30px;
		padding-bottom: 30px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.footer > .footer-title {
	margin: 0;
	opacity: .8;
}

/* Social network icons in the xs footer */
.footer .navbar.site-logo.site-logo-xs > .nav.navbar-nav > li.icons {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

.footer .navbar.site-logo.site-logo-xs > .nav.navbar-nav > li.icons > a {
	display: inline;
	font-size: 16px;
}

/* Social network icons in the footer */
.footer > .footer-copyright .icons a {
	margin-left: 15px;
}

/* Day/night toggle in the footer */
.footer .navbar.site-logo.site-logo-xs > .nav.navbar-nav > li.dark-toggle {
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
}

/* Copyright */
.footer > .footer-copyright > .copyright {
	opacity: .62;
}

.footer > .footer-copyright a {
	color: #fff;
	opacity: .62;
	transition: .2s ease-in-out;
	text-decoration: none;
}

.footer > .footer-copyright a:hover {
	opacity: 1;
	text-decoration: none;
}

/**
 * Back to top button
 **/

.back-to-top {
	z-index: 299;
	position: fixed;
	width: 40px;
	height: 40px;
	background-color: rgba(0,0,0,0.3);
	font-size: 20px;
	line-height: 36px;
	text-align: center;
	color: var(--alternate-fgcolor);
	top: auto;
	left: auto;
	right: 30px;
	bottom: 50px;
	cursor: pointer;
	border-radius: 2px;
	transition: .2s ease-in-out;
}

.back-to-top:hover {
	background-color: var(--alternate-bgcolor);
}


/**
 * zoomable
 *
 * zoomable thumbnail size is initialized with min-width and min-height.
 * Then animation from thumbnail to zoomed is done with a transition on
 * width from 0% to 100% (so, relative to the parent).
 * The zoomed size won't exceed max-width and max-height.
 *
 * The IMG element inside zoomable is constrained with max-width = 100%
 * and height: auto so that it grows and shrinks with the size of the
 * zoomable element.
 *
 * The full-screen state re-shuffles everything as a lightbox, using a
 * different z-index and position fixed.
 *
 * Do not mess with these CSS classes and attributes unless you really
 * know what you're doing.
 **/

zoomable {
	cursor: zoom-in;
	transition: width .3s ease-in-out;
	display: block;
	width: 0%;
	background-repeat: no-repeat;
	background-size: auto;
	position: relative;
	margin-bottom: 2em;
}

zoomable.zoomed {
	cursor: zoom-out;
	width: 100%;
}

.zoomable:focus {
	outline: none;
}

zoomable .zoomable-shadow {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	transition: box-shadow .3s ease-in-out;
}

zoomable .zoomable-shadow:hover {
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.full-screen.zoomable .zoomable-shadow {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 999999;
}

zoomable img {
	opacity: 0;
	max-width: 100%;
	height: auto !important;
	transition: opacity .3s ease-in-out;
}

zoomable.zoomed img {
	opacity: 1;
}

.zoomable.full-screen img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto auto;
	width: auto !important;
	height: auto !important;
	max-width: 90vw;
	max-height: 90vh;
}

.zoomable .zoomable-title {
	position: absolute;
	margin-top: 5px;
	width: 100%;
	text-align: center;
	color: #888;
	opacity: 0;
	font-size: 0%;
	transition: opacity .3s ease-in-out, font-size .3s ease-in-out;
}

.zoomed .zoomable .zoomable-title,
.zoomable.full-screen .zoomable-title {
	opacity: 1;
	font-size: 100%;
}

.zoomable.full-screen .zoomable-title {
	position: fixed;
	bottom: 10px;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
	z-index: 1000000;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	padding-top: 10px;
	padding-bottom: 10px;
}

.zoomable .zoomable-control {
	opacity: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1000000;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 10px;
	text-align: right;
	background-color: rgba(0, 0, 0, 0.2);
	transition: opacity .3s ease-in-out;
}

.zoomed:hover .zoomable .zoomable-control,
.zoomable.full-screen .zoomable-control {
	opacity: 1;
}

.zoomable .zoomable-control i {
	cursor: pointer;
	padding: 5px;
	font-size: 30px;
	color: rgba(255, 255, 255, 0.6);
	text-shadow: black;
}

.zoomable .zoomable-control i:hover {
	color: white;
}

/**
 * PrismJS customized theme
 **/
.language-psl .token.builtin {
	color: #e8e;
}

.language-psl .token.symbol {
	color: #fca;
}

.language-psl .token.operator {
	color: deepskyblue;
}

pre[class*="language-"]::selection,
code[class*="language-"]::selection,
pre[class*="language-"] *::selection,
code[class*="language-"] *::selection {
	text-shadow: none;
	background: #666;
}

body.sentry-site pre > code,
body.sentry-site pre {
	white-space: pre-wrap;
	background-color: #333;
	color: #d4d4d4;
	border: none;
}

body.sentry-site.dark pre > code,
body.sentry-site.dark pre {
	background-color: #242424;
}

/**
 * Adjustments of Bootstrap 3 for dark mode
 **/

/* Breadcrumbs */
body.sentry-site .main-content .breadcrumb {
	background: none;
	position: relative;
	z-index: 1;
}

body.sentry-site .main-content .breadcrumb::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-fgcolor);
	opacity: 0.05;
	z-index: -1;
	border-radius: 4px;
	pointer-events: none;
}

/* code */
body.sentry-site :not(pre) > code {
	background: none;
	position: relative;
	color: var(--main-fgcolor);
	z-index: 1;
	white-space: nowrap !important;
	overflow-x: auto;
}

body.sentry-site :not(pre) > code::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--alternate-bgcolor);
	opacity: 0.25;
	z-index: -1;
	border-radius: 4px;
	pointer-events: none;
}

/* kbd */
body.sentry-site kbd {
	background: none;
	position: relative;
	z-index: 1;
	color: var(--main-bgcolor);
	white-space: nowrap;
}

body.sentry-site kbd::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-fgcolor);
	opacity: 0.6;
	z-index: -1;
	border-radius: 3px;
	pointer-events: none;
}

/* Tables */
body.sentry-site .table > tbody > tr {
	background: none;
	position: relative;
	z-index: 1;
}

/* ::after for handling cells' background */
/* (stripes, colors) */
body.sentry-site .table > tbody > tr::after {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-fgcolor);
	opacity: 0;
	pointer-events: none;
	z-index: -2;
}

/* Stripes */
body.sentry-site .table-striped>tbody>tr:nth-of-type(odd)::after {
	opacity: 0.05;
}

/* ::before for handling .hover */
body.sentry-site .table.table-hover tr::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-fgcolor);
	opacity: 0;
	pointer-events: none;
	z-index: -1;
}

body.sentry-site .table-hover > tbody > tr:hover::before,
body.sentry-site .table-hover > tbody > tr.active::before {
	opacity: 0.1;
}

body.sentry-site .table-hover>tbody>tr:hover>td,
body.sentry-site .table-hover>tbody>tr:hover>th {
	background: none;
}

/* Prevent Bootstrap from setting colors on cells directly */
body.sentry-site .table>tbody>tr>td,
body.sentry-site .table>tbody>tr>th {
	background-color: inherit;
	color:inherit;
}

/* success, info, warning and danger colors in light and dark mode */
body.sentry-site .table>tbody>tr.success::after {
	background-color: var(--success-bg);
	opacity: 1;
}
body.sentry-site .table>tbody>tr.success {
	color: var(--success-fg);
}

body.sentry-site .table>tbody>tr.info::after {
	background-color: var(--info-bg);
	opacity: 1;
}
body.sentry-site .table>tbody>tr.info {
	color: var(--info-fg);
}

body.sentry-site .table>tbody>tr.warning::after {
	background-color: var(--warning-bg);
	opacity: 1;
}
body.sentry-site .table>tbody>tr.warning {
	color: var(--warning-fg);
}

body.sentry-site .table>tbody>tr.danger::after {
	background-color: var(--danger-bg);
	opacity: 1;
}
body.sentry-site .table>tbody>tr.danger {
	color: var(--danger-fg);
}

body.sentry-site .text-primary {
	color: var(--banner-bgcolor);
}
body.sentry-site .text-success,
body.sentry-site .alert-success {
	color: var(--success-fg);
}
body.sentry-site .text-info,
body.sentry-site .alert-info {
	color: var(--info-fg);
}
body.sentry-site .text-warning,
body.sentry-site .alert-warning {
	color: var(--warning-fg);
}
body.sentry-site .text-danger,
body.sentry-site .alert-danger {
	color: var(--danger-fg);
}

body.sentry-site .bg-primary {
	background-color: var(--banner-bgcolor);
	color: var(--banner-fgcolor);
}

body.sentry-site .bg-success,
body.sentry-site .alert-success {
	background-color: var(--success-bg);
}

body.sentry-site .bg-info,
body.sentry-site .alert-info {
	background-color: var(--info-bg);
}

body.sentry-site .bg-warning,
body.sentry-site .alert-warning {
	background-color: var(--warning-bg);
}

body.sentry-site .bg-danger,
body.sentry-site .alert-danger {
	background-color: var(--danger-bg);
}

/* Navigation bars with list items */
body.sentry-site .parents-xs .list-group-item,
body.sentry-site .home-xs .list-group-item,
body.sentry-site .subtopics-xs .list-group-item,
body.sentry-site .search-results-content-xs .list-group-item {
	background-color: var(--main-bgcolor);
	transition: background-color 1s ease-in-out;
}
